#camera--icon input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.fut-player-card {
  width: 250px;
  height: 415px;
  background-image: url("../assets/SVG//championsLeaguecard.png");
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3.8rem 0;
  z-index: 2;
  transition: 200ms ease-in;
}
.wrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  width: 100%;
  height: 50vh;
  min-height: 500px;
}
.fut-player-card .player-card-top {
  position: relative;
  display: flex;
  color: #fff;
  padding: 0 1.5rem;
}
.fut-player-card .player-card-top .player-master-info {
  position: absolute;
  line-height: 2.2rem;
  font-weight: 300;
  padding: 1.5rem 0;
  text-transform: uppercase;
}
.fut-player-card .player-card-top {
  position: relative;
  display: flex;
  color: #fff;
  padding: 0 1.5rem;
}

.fut-player-card .player-card-top .player-picture {
  width: 115px;
  height: 105px;
  margin: 0 auto;
  overflow: hidden;
}
.fut-player-card .player-card-top .player-picture .player-extra {
  position: absolute;
  right: 0;
  bottom: -0.5rem;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  height: 2rem;
  padding: 0 1.5rem;
  text-align: right;
  background: none;
}
.fut-player-card .player-card-bottom {
  position: relative;
}
.fut-player-card .player-card-bottom .player-info {
  display: block;
  padding: 0.3rem 0;
  color: #fff;
  width: 90%;
  margin: 0 auto;
  height: auto;
  position: relative;
  z-index: 2;
}
.fut-player-card .player-card-bottom .player-info .player-name {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-bottom: 2px solid rgba(233, 204, 116, 0.1);
  padding-bottom: 0.3rem;
  overflow: hidden;
}
.fut-player-card .player-card-bottom .player-info .player-features {
  margin: 0.5rem auto;
  display: flex;
  justify-content: center;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col {
  border-right: 2px solid rgba(233, 204, 116, 0.1);
  padding: 0 2.3rem;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-value {
  margin-right: 0.3rem;
  font-weight: 700;
}
.fut-player-card .player-card-bottom .player-info .player-features .player-features-col span .player-feature-title {
  font-weight: 300;
}
.fut-player-card .player-card-top .player-master-info .player-nation {
  display: block;
  width: 2rem;
  height: 25px;
  margin: 0.3rem 0;
}
.fut-player-card .player-card-top .player-master-info .player-club {
  display: block;
  width: 2.1rem;
  height: 40px;
}